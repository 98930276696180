import './App.css';
import { useState, useEffect } from 'react';
import { getDatabase, ref, set, get, child } from "firebase/database";
import Web3 from 'web3';
import { 
  Button, 
  Typography, 
  Container, 
  Box, 
  ThemeProvider, 
  createTheme,
  CssBaseline,
  Grid,
  Paper,
} from '@mui/material';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDFT-j6sQ50kLMEWvUP2X9E0xV_1YajBd4",
  authDomain: "theevillagee-83e8a.firebaseapp.com",
  databaseURL: "https://theevillagee-83e8a-default-rtdb.firebaseio.com",
  projectId: "theevillagee-83e8a",
  storageBucket: "theevillagee-83e8a.appspot.com",
  messagingSenderId: "804295414802",
  appId: "1:804295414802:web:b18b3a112fc55cf9bf34cb",
  measurementId: "G-CVT22CRZ4S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

function App() {
  const [walletAddress, setWalletAddress] = useState(null);
  const [userCount, setUserCount] = useState(0);
const POLYGON_MAINNET_RPC = "https://polygon-rpc.com";
const POLYGON_MAINNET_CHAIN_ID = "0x89"; // 137 in decimal

function writeData(userId) {
  const db = getDatabase();
  const timestamp = Date.now();
  set(ref(db, 'users/' + userId), {
    time: timestamp,
    wins: 0
  });
}

async function getUserCount() {
  const dbRef = ref(getDatabase());
  try {
    const snapshot = await get(child(dbRef, 'users'));
    if (snapshot.exists()) {
      const usersData = snapshot.val();
      const userCount = Object.keys(usersData).length;
      console.log('Number of users:', userCount);
      return userCount;
    } else {
      console.log('No users found');
      return 0;
    }
  } catch (error) {
    console.error('Error getting user count:', error);
    return 0;
  }
}

async function connectWallet() {
  if (typeof window.ethereum !== 'undefined') {
    const web3 = new Web3(window.ethereum);

    try {
      // Request account access
      const accounts = await web3.eth.requestAccounts();
      const userAddress = accounts[0];

      // Check if the user is connected to Polygon
      const chainId = await web3.eth.getChainId();
      if (chainId !== parseInt(POLYGON_MAINNET_CHAIN_ID, 16)) {
        // If not on Polygon, request network switch
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: POLYGON_MAINNET_CHAIN_ID }],
          });
          writeData(userAddress);
          return userAddress
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: POLYGON_MAINNET_CHAIN_ID,
                  chainName: 'Polygon Mainnet',
                  nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                  },
                  rpcUrls: [POLYGON_MAINNET_RPC],
                  blockExplorerUrls: ['https://polygonscan.com/']
                }],
              });
            } catch (addError) {
              alert('Failed to add Polygon network:');
              console.error('Failed to add Polygon network:', addError);
              return;
            }
          } else {
            alert('Failed to switch to Polygon network:');
            console.error('Failed to switch to Polygon network:', switchError);
            return;
          }
        }
      }

      console.log('Connected to Polygon with address:', userAddress);
      setWalletAddress(userAddress);
    } catch (error) {
      alert('Failed to connect to the network:');
      console.error('User rejected the request:', error);
    }
  } else {
    console.log('MetaMask is not installed.');
  }
}


  const handleSignUp = async () => {
    if (userCount >= 128) {
      alert('The tournament is full. Come back next time!');
      return;
    }
    const address =await connectWallet();
    if (!address) {
      alert('Please connect your Web3 wallet first.');
      return;
    } else {
      getUserCount().then(count => {
        setUserCount(count);
        console.log('User count:', count);
      });
      alert('Signed up with address: ' + address);
    }
    
    console.log('Sign Up with wallet address:', walletAddress);
    // Here you can proceed with your sign-up logic using the wallet address
    }

    useEffect(() => {
      getUserCount().then(count => {
        setUserCount(count);
        console.log('User count:', count);
      });
    }, []);

    const renderTicTacToeBoard = () => {
      return (
        <Grid container spacing={1} sx={{ width: 150, margin: 'auto', mt: 2 }}>
          {[...Array(9)].map((_, index) => (
            <Grid item xs={4} key={index}>
              <Paper 
                sx={{ 
                  height: 50, 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  backgroundColor: 'primary.light'
                }} 
                elevation={3}
              >
                {index === 0 ? (
                <div>X</div>
              ) : index === 1 ? (
                <div>O</div>
              ) : index === 2 ? (
                <div>X</div>
              ) : index === 3 ? (
                <div>O</div>
              ) : index == 4 ? (
                <div>X</div>
              ) : index === 5 ? (
                <div>O</div>
              ) : index === 6 ? (
                <div>O</div>
              ) : index === 7 ? (
                <div>X</div>
              ) : (
                <div><button onClick={handleSignUp} style={{
                  width: '130%',
                  height: '130%',
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  touchAction: 'manipulation'
                }}>&nbsp;</button></div>
              )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      );
    };

  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container maxWidth="sm">
      <Box sx={{ my: 4, textAlign: 'center' }}>
      <Typography variant="h6" component="h2" gutterBottom>
            Players signed up: {userCount} / 128
          </Typography>
      </Box>
      {renderTicTacToeBoard()}
    </Container>
  </ThemeProvider>
  );
}

export default App;
